<template>
  <div class="flow_contanter">
    <headerTab></headerTab>
    <!-- 流程表 -->
    <div class="flow_content">
      <div class="lookMore" @click="lookMore" v-if="lookTab&&!tableData.trade_state==0">查看更多流程>></div>
      <div class="lookMore" @click="lookMore(1)" v-if="lookTab==false">收起流程>></div>
      <div v-if="messageList[0].itemShow==false">......</div>
      <div class="flow_list" v-for="(item,index) in messageList" :key="index" v-show="item.itemShow">
        <div class="flow_header">
          <div class="flow_box" :class="(index<step||index==10)?'bs_line':'bg_line'"></div>
          <div class="line_flow" v-if="(index!=9&&index!=10)||(index==9&&tableData.trade_state==0)" :class="index<step?'bs_line':'bg_line'"></div>
        </div>
        <div v-if="index==0">
          <div style="display:flex">
            <div class="flow_title" style="color:#FF5E5E">{{item.title}}</div>
            <img class="flow_imgBtn" v-if="tableData.audit_state==1" src="../../assets/successIcon2.png" />
            <img class="flow_imgBtn" v-if="tableData.audit_state==2" src="../../assets/failIcon.png" />
          </div>
          <div class="flow_text1" style="color:#2E2E30;display:flex" v-if="tableData.audit_state==0">
            {{item.applyText}}
          </div>
          <div class="flow_text1" style="color:#FF5E5E" v-if="tableData.audit_state==2">{{tableData.audit_remark.remark}}</div>
          <div class="flow_text1" style="color:#07C160" v-if="tableData.audit_state==1">{{item.applySuccessText}}</div>
          <div class="flow_text1" style="color:#2E2E30" v-if="tableData.audit_state>=1">{{tableData.audit_at}}</div>
        </div>

        <div v-if="index==1">
          <div style="display:flex">
            <div class="flow_title" :class="tableData.audit_state>=1?'bc':'bg'">{{item.title}}</div>
            <img class="flow_imgBtn" v-if="tableData.trade_info_state==1" src="../../assets/successIcon2.png" />
          </div>
          <div class="flow_text1" style="color:#2E2E30;display:flex" v-if="tableData.audit_state>=1&&tableData.trade_state!=0">
            {{item.applyText}}
            <div class="check" @click="checkBtn" v-if="tableData.trade_info_state==0">完善资料<img src="../../assets/rightIcon4.png" /></div>
            <div class="check" @click="checkBtn" v-if="tableData.platform_state==0&&tableData.trade_info_state>0">修改资料<img src="../../assets/rightIcon4.png" /></div>
          </div>
          <div v-if="tableData.trade_state==0&&step==2">
            <div class="flow_text1" style="color:#FF3E46" v-if="tableData.close_remark">转让关闭，原因:{{tableData.close_remark.remark}}</div>
            <div class="flow_text1" style="color:#FF3E46" v-if="tableData.close_remark">{{tableData.close_at}}</div>
          </div>
          <div class="flow_text1" style="color:#07C160" v-if="tableData.trade_info_state>=1">{{item.applySuccessText}}</div>
          <div class="flow_text1" style="color:#2E2E30" v-if="tableData.trade_info_state>=1">{{tableData.trade_info_at}}</div>
        </div>

        <div v-if="index==2">
          <div style="display:flex">
            <div class="flow_title" :class="tableData.trade_info_state>=1?'bc':'bg'">{{item.title}}</div>
            <img class="flow_imgBtn" v-if="tableData.trade_info_state==1&&tableData.tax_pay_state==1" src="../../assets/successIcon2.png" />
            <img class="flow_imgBtn" v-if="tableData.trade_info_state==1&&tableData.tax_pay_state==2" src="../../assets/failIcon.png" />
          </div>
          <div class="flow_text1" style="color:#2E2E30;display:block" v-if="tableData.trade_info_state==1&&tableData.tax_pay_state>=0&&tableData.trade_state!=0||tableData.tax_pay_state==1&&tableData.trade_state==0">
            <div style="margin-bottom:5px" v-if="tableData.tax_pay_state==0&&tableData.pay_time!=null">支付剩余时间: {{(Math.trunc(tableData.pay_time / 3600) +
              "小时" +
              Math.trunc((tableData.pay_time % 3600) / 60) +
              "分" +
              ((tableData.pay_time % 3600) % 60) +
              "秒")}}</div>
            <div style="display:flex">
              <div>服务费：{{tableData.sales.tax_amount}}<span style="color:#0F87FF;margin-left:10px" v-if="tableData.tax_pay_state==1">已支付</span></div>
              <div class="check" v-if="tableData.tax_pay_state==0" @click="payBtn(1)">点击支付<img src="../../assets/rightIcon4.png" /></div>
            </div>
          </div>
          <div v-if="tableData.trade_state==0&&step==3">
            <div class="flow_text1" style="color:#FF3E46" v-if="tableData.close_remark">转让关闭，原因:{{tableData.close_remark.remark}}</div>
            <div class="flow_text1" style="color:#FF3E46" v-if="tableData.close_remark">{{tableData.close_at}}</div>
          </div>
          <div v-if="tableData.tax_pay_state==2">
            <div class="flow_text1" style="color:#FF3E46">转让关闭，原因:支付异常</div>
            <div class="flow_text1" style="color:#FF3E46">{{tableData.trade_info_at}}</div>
          </div>
          <div class="flow_text1" style="color:#0F87FF" v-if="tableData.trade_info_state==1&&tableData.tax_pay_state==1">{{tableData.tax_pay_at}}</div>
        </div>

        <div v-if="index==3">
          <div style="display:flex">
            <div class="flow_title" :class="tableData.tax_pay_state>=1?'bc':'bg'">{{item.title}}</div>
            <img class="flow_imgBtn" v-if="tableData.payment_state==1" src="../../assets/successIcon2.png" />
            <img class="flow_imgBtn" v-if="tableData.payment_state==2" src="../../assets/failIcon.png" />
          </div>
          <div class="flow_text1" style="color:#2E2E30;display:flex" v-if="tableData.tax_pay_state>=1&&tableData.payment_state==0&&tableData.trade_state!=0">
            {{item.applyText}}
          </div>
          <div v-if="tableData.trade_state==0&&step==4">
            <div class="flow_text1" style="color:#FF3E46" v-if="tableData.close_remark">转让关闭，原因:{{tableData.close_remark.remark}}</div>
            <div class="flow_text1" style="color:#FF3E46" v-if="tableData.close_remark">{{tableData.close_at}}</div>
          </div>
          <div class="flow_text1" style="color:#07C160" v-if="tableData.payment_state==1">{{item.applySuccessText}}</div>
          <div class="flow_text1" style="color:#FF3E46" v-if="tableData.payment_state==2">居间审核拒绝，转让关闭</div>
          <div class="flow_text1" style="color:#07C160" v-if="tableData.payment_state!=0">{{tableData.payment_at}}</div>
        </div>

        <div v-show="index==4">
          <div style="display:flex">
            <div class="flow_title" :class="tableData.payment_state>=1?'bc':'bg'">{{item.title}}</div>
            <img class="flow_imgBtn" v-if="tableData.contract_state==1" src="../../assets/successIcon2.png" />
          </div>
          <div class="flow_text1" style="color:#2E2E30;display:flex" v-if="tableData.payment_state>=1&&tableData.contract_state==0&&tableData.trade_state!=0">
            {{item.applyText}}
          </div>
          <div v-if="tableData.trade_state==0&&step==5">
            <div class="flow_text1" style="color:#FF3E46" v-if="tableData.close_remark">转让关闭，原因:{{tableData.close_remark.remark}}</div>
            <div class="flow_text1" style="color:#FF3E46" v-if="tableData.close_remark">{{tableData.close_at}}</div>
          </div>
          <div v-if="tableData.contract_state==2">
            <div class="flow_text1" style="color:#FF3E46">转让关闭，原因:{{tableData.contract_info_remark.length>0?tableData.contract_info_remark[0].remark:'合同审核失败'}}</div>
            <div class="flow_text1" style="color:#FF3E46">{{tableData.contract_at}}</div>
          </div>
          <div class="flow_text1" style="color:#07C160" v-if="tableData.contract_state==1">{{item.applySuccessText}}</div>
          <div class="flow_text1" style="color:#2E2E30" v-if="tableData.contract_state==1">{{tableData.payment_info_at}}</div>
        </div>

        <div v-if="index==5">
          <div style="display:flex">
            <div class="flow_title" :class="tableData.contract_state==1?'bc':'bg'">{{item.title}}</div>
            <img class="flow_imgBtn" v-if="tableData.remit_state==1" src="../../assets/successIcon2.png" />
            <img class="flow_imgBtn" v-if="tableData.remit_state==2" src="../../assets/failIcon.png" />
          </div>
          <div class="flow_text1" style="color:#2E2E30;display:block" v-if="(tableData.contract_state==1&&tableData.remit_state>=0&&tableData.trade_state!=0)||(tableData.remit_state==1&&tableData.trade_state==0)||(tableData.remit_state==1)">
            <div style="margin-bottom:5px;" v-if="tableData.remit_state==0&&tableData.pay_time!=null">支付剩余时间: <span style="color:#FF3E46">{{(Math.trunc(tableData.pay_time / 3600) +
                "小时" +
                Math.trunc((tableData.pay_time % 3600) / 60) +
                "分" +
                ((tableData.pay_time % 3600) % 60) +
                "秒")}}</span></div>
            <div style="display:flex" v-if="tableData.receipt_state!=3">
              <div>受让款项：<span style="color:#0F87FF">{{tableData.sales.shares_sales}}</span><span style="color:#0F87FF;margin-left:10px" v-if="tableData.remit_state==1">已支付</span></div>
              <div class="check" v-if="tableData.remit_state==0" @click="payBtn(2)">点击支付<img src="../../assets/rightIcon4.png" /></div>
            </div>
            <div class="flow_text1" style="display:flex" v-else>
              <div v-if="tableData.receipt_state==3" style="color:#FF3E46">经核实，打款异常！</div>
              <div class="check" v-if="tableData.receipt_state==3" @click="payBtn(2)">请补充打款<img src="../../assets/rightIcon4.png" /></div>
            </div>

          </div>
          <div v-if="tableData.trade_state==0&&step==6">
            <div class="flow_text1" style="color:#FF3E46" v-if="tableData.close_remark">转让关闭，原因:{{tableData.close_remark.remark}}</div>
            <div class="flow_text1" style="color:#FF3E46" v-if="tableData.close_remark">{{tableData.close_at}}</div>
          </div>
          <div class="flow_text1" style="color:#07C160" v-if="tableData.remit_state==1">{{item.applySuccessText}}</div>
          <div class="flow_text1" style="color:#FF3E46" v-if="tableData.remit_state==2">打款超时，转让关闭</div>
          <div class="flow_text1" style="color:#2E2E30" v-if="tableData.remit_state==1">{{tableData.return_at}}</div>

        </div>

        <div v-if="index==6">
          <div style="display:flex">
            <div class="flow_title" :class="tableData.remit_state==1||tableData.receipt_state!=0?'bc':'bg'">{{item.title}}</div>
            <img class="flow_imgBtn" v-if="tableData.receipt_state==1" src="../../assets/successIcon2.png" />
            <img class="flow_imgBtn" v-if="tableData.receipt_state==3" src="../../assets/failIcon.png" />
          </div>
          <div class="flow_text1" style="color:#2E2E30;display:flex" v-if="tableData.remit_state>=1&&tableData.receipt_state==0&&tableData.trade_state!=0">
            {{item.applyText}}
          </div>
          <div v-if="tableData.trade_state==0&&step==7">
            <div class="flow_text1" style="color:#FF3E46" v-if="tableData.close_remark">转让关闭，原因:{{tableData.close_remark.remark}}</div>
            <div class="flow_text1" style="color:#FF3E46" v-if="tableData.close_remark">{{tableData.close_at}}</div>
          </div>
          <div class="flow_text1" style="display:flex">
            <div v-if="tableData.receipt_state==2" style="color:#FF3E46">转让收款异常，等待平台核实，请保持电话畅通</div>
          </div>
          <div class="flow_text1" style="color:#07C160" v-if="tableData.receipt_state==1">{{item.applySuccessText}}</div>
          <div class="flow_text1" style="color:#FF3E46">{{tableData.receipt_at}}</div>
        </div>

        <div v-if="index==7">
          <div style="display:flex">
            <div class="flow_title" :class="tableData.receipt_state==1?'bc':'bg'">{{item.title}}</div>
            <img class="flow_imgBtn" v-if="tableData.trade_pay_at" src="../../assets/successIcon2.png" />
            <img class="flow_imgBtn" v-if="tableData.receipt_state==1&&!settingData.shares_trade_shipping_fee&&tableData.trade_pay_amount==0" src="../../assets/successIcon2.png" />
          </div>
          <div class="flow_text1" style="color:#2E2E30;display:flex" v-if="tableData.receipt_state==1&&tableData.trade_state!=0&&settingData.shares_trade_shipping_fee">
            <div style="display:flex">
              <div style="color:#07C160">运费：<span style="color:#07C160">{{tableData.trade_pay_admin_id?tableData.trade_pay_amount:settingData.shares_trade_shipping_fee}}</span><span style="color:#0F87FF;margin-left:10px" v-if="tableData.trade_pay_at">已支付</span></div>
              <div class="check" v-if="!tableData.trade_pay_at" @click="payBtn(3)">点击支付<img src="../../assets/rightIcon4.png" /></div>
            </div>
          </div>
          <div v-if="tableData.trade_state==0&&step==8">
            <div class="flow_text1" style="color:#FF3E46" v-if="tableData.close_remark">转让关闭，原因:{{tableData.close_remark.remark}}</div>
            <div class="flow_text1" style="color:#FF3E46" v-if="tableData.close_remark">{{tableData.close_at}}</div>
          </div>
          <div class="flow_text1" style="color:#07C160" v-if="tableData.receipt_state==1&&tableData.trade_state!=0&&!settingData.shares_trade_shipping_fee&&tableData.trade_pay_amount==0">已完成</div>
          <div class="flow_text1" style="color:#07C160" v-if="tableData.receipt_state>=1">{{item.applySuccessText}}</div>
          <div class="flow_text1" style="color:#07C160" v-if="tableData.receipt_state>=1">{{tableData.trade_pay_at}}</div>
        </div>

        <div v-if="index==8">
          <div style="display:flex">
            <div class="flow_title" :class="tableData.trade_pay_at||(tableData.receipt_state==1&&!settingData.shares_trade_shipping_fee&&tableData.trade_pay_amount==0)?'bc':'bg'">{{item.title}}</div>
            <img class="flow_imgBtn" v-if="tableData.platform_state==1" src="../../assets/successIcon2.png" />
            <img class="flow_imgBtn" v-if="tableData.platform_state==2" src="../../assets/failIcon.png" />
          </div>
          <div class="flow_text1" style="color:#2E2E30;display:flex" v-if="tableData.trade_pay_at&&tableData.platform_state==0&&tableData.trade_state!=0||(tableData.receipt_state==1&&!settingData.shares_trade_shipping_fee&&tableData.trade_pay_amount==0&&tableData.trade_state!=0)">
            {{item.applyText}}
          </div>
          <div v-if="tableData.trade_state==0&&step==9">
            <div class="flow_text1" style="color:#FF3E46" v-if="tableData.close_remark">转让关闭，原因:{{tableData.close_remark.remark}}</div>
            <div class="flow_text1" style="color:#FF3E46" v-if="tableData.close_remark">{{tableData.close_at}}</div>
          </div>
          <div class="flow_text1" style="color:#07C160" v-if="tableData.platform_state>=1">{{item.applySuccessText}}</div>
          <div class="flow_text1" style="color:#2E2E30" v-if="tableData.platform_state>=1">{{tableData.receipt_at}}</div>
        </div>

        <div v-if="index==9&&!(tableData.trade_state==0&&tableData.platform_state!=1&&tableData.trade_pay_at)">
          <div style="display:flex">
            <div class="flow_title" :class="tableData.trade_pay_at?'bc':'bg'">{{item.title}}</div>
            <img class="flow_imgBtn" v-if="tableData.trade_shipments.length>=1" src="../../assets/successIcon2.png" />
            <img class="flow_imgBtn" v-if="tableData.platform_state==2" src="../../assets/failIcon.png" />
          </div>
          <div class="flow_text1" style="color:#2E2E30;display:flex" v-if="tableData.trade_shipments.length==0&&tableData.platform_state>=1&&tableData.trade_state!=0">
            {{item.applyText}}
          </div>
          <div v-if="tableData.trade_state==0&&step==10">
            <div class="flow_text1" style="color:#FF3E46" v-if="tableData.close_remark">转让关闭，原因:{{tableData.close_remark.remark}}</div>
            <div class="flow_text1" style="color:#FF3E46" v-if="tableData.close_remark">{{tableData.close_at}}</div>
          </div>
          <div class="flow_text1" style="color:#07C160" v-if="tableData.trade_shipments.length>=1">{{item.applySuccessText}}<span @click="logistics1" style="color:#0F87FF;margin-left:10px">查看详情></span></div>
          <div class="flow_text1" style="color:#2E2E30" v-if="tableData.trade_shipments.length>=1">{{tableData.receipt_at}}</div>
        </div>
        <div v-if="tableData.trade_state==0&&index==10">
          <div style="display:flex">
            <div class="flow_title bc">已结束</div>
          </div>
        </div>

      </div>
      <div v-if="messageList[4].itemShow==false">......</div>
    </div>
    <div class="footerText" v-if="tableData.trade_state==0">
        <img class="flow_imgBtn" style="margin-bottom:30px;margin-right:10px" src="../../assets/failIcon.png"/>
        <div>
            温馨提示，在受让流程中存在支付款项，受让异常时，
                <span>支出款项会经平台审核后将在1~3工作日处理</span>
                可查看<span style="color:#0F87FF" @click="goReturn">退款列表</span>跟踪进度
        </div>      
    </div>

    <div class="flow_popbox" v-if="popShow">
      <div class="flow_popbox">
        <div class="flow_pop1" v-if="popShow1">
          <img @click="submitBtn" style="width:10px;height:10px;" src="../../assets/cloneBtn2.png" />
          <div class="pop1">
            <div v-if="tableData.trade_shipments.length==1">
              <div style="color:#FF5E5E;margin-bottom:20px">*请将收到的全部物料按要求填写完毕寄回。</div>

              <div class="formList_flow" style="margin-bottom:20px">
                <div class="offine_title_flow">快递公司:</div>
                <input v-model="dataForm.company.value" />
              </div>
              <div class="formList_flow" style="margin-bottom:20px">
                <div class="offine_title_flow">快递单号:</div>
                <input @focus='test' v-model="dataForm.order_sn.value" />
              </div>
              <div class="formList_flow" style="margin-bottom:20px">
                <div class="offine_title_flow">备注:</div>
                <textarea v-model="dataForm.message.value"></textarea>
              </div>
              <div class="submitBtn" @click="companyBtn">提交</div>
            </div>
          </div>
        </div>

        <div v-if="popShow3" class="shipper_box">
          <img @click="submitBtn" class="cloneBtn2" style="width:10px;height:10px;" src="../../assets/cloneBtn2.png" />
          <div class="loginBtn1">
            <div style="text-align:right;width:100px;margin-right:30px">物流单号:</div>
            <div>{{dataList.LogisticCode}}</div>
          </div>
          <div class="loginBtn1">
            <div style="text-align:right;width:100px;margin-right:30px">收件人:</div>
            <div>{{tableData.trade_shipments[0].shipments_info.name}}</div>
          </div>
          <div class="loginBtn1">
            <div style="text-align:right;width:100px;margin-right:30px">收件人地址:</div>
            <div>{{tableData.trade_shipments[0].shipments_info.address}}</div>
          </div>
          <div class="steps1" style="color:#000" v-if="dataList.State==3">
            <div>
              <div style="margin-left:-10px" class="boxcolor3">收</div>
              <div class="linecolor1"></div>
            </div>
            <div class="neth">
              <div style="color:#00000080">[收货地址]{{tableData.trade_shipments[0].shipments_info.address}}</div>
            </div>
          </div>
          <div class="steps1" v-for="(item,index) in dataList.Traces" v-bind:key="index">
            <div v-if="index!=0">
              <div class="boxcolor2"></div>
              <div class="linecolor1"></div>
            </div>
            <div v-else>
              <div class="boxcolor1"></div>
              <div class="linecolor1"></div>
            </div>
            <div :class="index==0&&item.Action!=3?'neth colorText':'neth'">
              <div v-if="item.Action==1">以揽件{{item.AcceptTime}}</div>
              <div v-if="item.Action==2||item.Action==201">运输中{{item.AcceptTime}}</div>
              <div v-if="item.Action==202">派送中{{item.AcceptTime}}</div>
              <div v-if="item.Action==211">待取件{{item.AcceptTime}}</div>
              <div v-if="item.Action==3">已签收{{item.AcceptTime}}</div>
              <div>{{item.AcceptStation}}</div>
            </div>
          </div>
          <div class="steps1" v-if="dataList.State==2">
            <div>
              <div :class="dataList.State==0?'boxcolor1':'boxcolor2'"></div>
              <div class="linecolor1"></div>
            </div>
          </div>
          <div class="steps1">
            <div>
              <div class="boxcolor2"></div>
            </div>
            <div class="neth">
              <div>已下单{{dataList.pay_at}}</div>
            </div>
          </div>
          <div class="order" v-if="tableData.trade_shipments.length>0&&!tableData.trade_shipments[0].receive_at" @click="getOrder">确认收货</div>
        </div>
      </div>
    </div>

    <!-- 提示框 -->
    <toast v-if="toastShow" :config="configData"></toast>
  </div>
</template>
<script type="text/javascript">
import * as apiCommon from "@/api/common";
import {
  getShipments_api,
  // tradeShipments_api,
  deliverShipments_api,
  // gettradeShipmentsList_api
} from "@/api/admin";
import { cloneOrder_api, getConfig_api } from "@/api/deal";
import headerTab from "../../components/headerTab.vue";
import toast from "@/components/toast";
// import wx from "weixin-js-sdk";
import { Toast } from "vant";
export default {
  data() {
    return {
      dataForm: {
        company: {
          value: "",
          title: "快递公司",
          text: "请输入快递公司",
          color: "#DEB77A"
        },
        order_sn: {
          value: "",
          title: "快递单号",
          text: "请输入快递单号",
          color: "#DEB77A"
        },
        message: {
          value: "",
          title: "备注",
          text: "请输入备注",
          color: "#DEB77A"
        }
      },
      popShow: false,
      popShow1: false,
      popShow2: false,
      popShow3: false,
      tableData: {
        info_state: "",
        trade_shipments: [],
        info_remark: [],
        audit_remark: []
      },
      dataList: null,
      queryList: null,
      companyCode: "",
      toastShow: false,
      index: 0,
      listShow: false, //流程隐藏
      messageList: [
        {
          title: "订单确认",
          applyText: "请耐心等待，保持通话畅通",
          applySuccessText: "受让意向确认",
          itemShow: true
        },
        {
          title: "信息确认",
          applyText: "按要求完善资料，签署协议",
          applySuccessText: "信息确认完毕",
          itemShow: true
        },
        {
          title: "服务资费",
          applyText: "",
          applySuccessText: "",
          itemShow: true
        },
        {
          title: "信息审核",
          applyText: "等待平台信息审核确认",
          applySuccessText: "审核通过，信息确认无误",
          itemShow: true
        },
        {
          title: "转让审核",
          applyText: "等待转让方回寄工分合同",
          applySuccessText: "转让确认完毕",
          itemShow: false
        },
        {
          title: "受让打款",
          applyText: "",
          applySuccessText: "",
          itemShow: false
        },
        {
          title: "款项确认",
          applyText: "等待转让者确认款项",
          applySuccessText: "转让者确认款项已完成",
          itemShow: false
        },
        {
          title: "支付运费",
          applyText: "等待支付物料邮寄费用",
          applySuccessText: "",
          itemShow: false
        },
        {
          title: "信息变更",
          applyText: "等待平台处理，生成合同/用户数据！",
          applySuccessText: "处理完成，合同数据已生成",
          itemShow: false
        },
        {
          title: "物料邮寄",
          applyText: "等待物流发货，请关注提示！",
          applySuccessText: "已发货",
          itemShow: false
        },
        {
          title: "已结束",
          applyText: "",
          applySuccessText: "",
          itemShow: false
        }
      ],
      settingData: {},
      step: "",
      lookTab:true
    };
  },
  components: {
    toast,
    headerTab
  },
  async created() {
    document.title = "订单详情";
    this.queryList = this.$route.query;
    this.getConfig();
    // if(this.queryList.audit_id){
    await this.getDetails(this.queryList.id);
    // 获取配置
    
    // }
  },
  methods: {
    getConfig() {
      let data = {
        config: ["shares_trade_shipping_fee"]
      };
      getConfig_api(data).then(res => {
        console.log(res);
        if (res.code == 0) {
          this.settingData = res.data;
          this.settingData.shares_trade_shipping_fee = Number(this.settingData.shares_trade_shipping_fee)
          this.$forceUpdate();
        }
      });
    },
    checkBtn() {
      this.$router.push(`./messages?id=${this.tableData.trade_id}`);
    },
    lookMore(index) {
      if (index == 1) {
        this.lookTab = true
        if (this.tableData.trade_state == 0) {
          this.messageList.forEach((e, index) => {
            if (index > this.step - 1) {
              console.log(1);
              e.itemShow = false;
            } else {
              e.itemShow = true;
            }
          });
          this.messageList[10].itemShow = true;
          console.log(this.step, "2", this.messageList);
        } else {
          
          if (this.step > 4) {
            this.messageList.forEach((item, index) => {
              if (index > 3) {
                item.itemShow = true;
              } else {
                item.itemShow = false;
              }
            });
          }else{
            this.messageList.forEach((item, index) => {
              if (index <= 3) {
                item.itemShow = true;
              } else {
                item.itemShow = false;
              }
            });
          }
          this.messageList[10].itemShow = false;
          console.log(this.messageList,"messageList")
        }
      } else {
        this.lookTab = false
        this.messageList.forEach(item => {
          item.itemShow = true;
        });
        if (this.tableData.trade_state == 0) {
          this.messageList[10].itemShow = true;
        } else {
          this.messageList[10].itemShow = false;
        }
      }
      console.log(1);

      this.$forceUpdate();
    },
    cloneBtn() {
      cloneOrder_api(this.queryList.id).then(res => {
        if (res.code == 0) {
          Toast({
            message: "操作成功",
            icon: "success",
            duration: 1000
          });
          this.$router.go(-1);
        }
      });
    },
    retunBtn() {
      this.$router.push("./return");
    },
    // 信息确认
    messageBtn() {
      this.$router.push(`./messages?id=${this.tableData.trade_id}`);
    },
    mineMessage() {
      // wx.closeWindow();
      this.$router.push("../lador/service");
    },
    detailsBtn() {
      this.$router.push("../contract");
    },
    // test() {
    //   let data = {
    //     LogisticCode: this.dataForm.order_sn
    //   };
    //   gettradeShipmentsList_api(data).then(res => {
    //     if (res.code == 0) {
    //       this.companyCode = res.code;
    //     }
    //   });
    // },
    logistics() {
      this.tableData.trade_shipments[0].shipments_info.logistic = 0;
      console.log(this.tableData.trade_shipments[0].shipments_info);
      getShipments_api(this.tableData.trade_shipments[0].shipments_info).then(
        res => {
          console.log(res.data);
          if (res.code == 0) {
            this.dataList = res.data;
            if (this.dataList) {
              this.dataList.Traces.reverse();
            }
            console.log(this.dataList);
            this.$forceUpdate();
          }
        }
      );
    },
    submitBtn() {
      console.log(1);
      this.popShow = false;
      this.popShow2 = false;
      this.popShow1 = false;
      this.popShow3 = false;
    },
    logistics1() {
      if (this.dataList.Traces.length == 0) {
        Toast({
          message: "暂无物流信息",
          icon: "none",
          duration: 1000
        });
        return false;
      }
      this.popShow = true;
      this.popShow3 = true;
      this.$forceUpdate();
    },
    lookAddress() {
      this.popShow = true;
      this.popShow1 = true;
    },
    lookBtn() {
      if (this.queryList.status) {
        this.$router.push("./index");
      } else {
        this.$router.push(`./index?id=${this.tableData.audit_id}`);
      }
    },
    getOrder() {
      this.tableData.trade_shipments[0].shipments_info.logistic = 0;
      deliverShipments_api(this.tableData.trade_shipments[0].shipments_id).then(
        res => {
          if (res.code == 0) {
            this.popShow = false;
            this.popShow3 = false;
            this.getDetails(this.queryList.id);
          }
        }
      );
    },
    // companyBtn() {
    //   for (var i in this.dataForm) {
    //     if (!this.dataForm[i]) {
    //       return false;
    //     }
    //   }
    //   let data = {
    //     audit_id: this.queryList.audit_id,
    //     audit_type: 1,
    //     shipments_info: {
    //       company: this.dataForm.company.value,
    //       order_sn: this.dataForm.order_sn.value,
    //       message: this.dataForm.message.value
    //     }
    //   };
    //   tradeShipments_api(data).then(res => {
    //     if (res.code == 0) {
    //       this.popShow = false;
    //       this.popShow2 = false;
    //       this.popShow1 = false;
    //       this.popShow3 = false;
    //       location.reload();
    //     }
    //   });
    // },
    async getDetails(id) {
      let res = await apiCommon.putOrder_api(id);
      if (res.code == 0) {
        console.log(res.data);
        this.tableData = res.data;
        // 判断流程走到第几步
        if (this.tableData.audit_state == 0) {
          this.step = 1;
        }
        if (
          this.tableData.audit_state > 0 &&
          this.tableData.trade_info_state == 0
        ) {
          this.step = 2;
        }
        if (
          this.tableData.trade_info_state > 0 &&
          (this.tableData.tax_pay_state == 0 ||
            this.tableData.tax_pay_state == 2)
        ) {
          this.step = 3;
        }
        if (
          this.tableData.tax_pay_state == 1 &&
          this.tableData.payment_state !=1
        ) {
          this.step = 4;
        }
        if (
          this.tableData.payment_state ==1 &&
          this.tableData.contract_state != 1
        ) {
          this.step = 5;
        }
        if (
          this.tableData.contract_state == 1 &&
          this.tableData.remit_state!=1
        ) {
          this.step = 6;
        }
        if (
          this.tableData.remit_state == 1 &&
          this.tableData.receipt_state != 1
        ) {
          this.step = 7;
        }
        if ((this.tableData.receipt_state == 1 && !this.tableData.trade_pay_at)) {
          this.step = 8;
        }
        if ((this.tableData.trade_pay_at && this.tableData.platform_state == 0)||(this.tableData.receipt_state==1&&!this.settingData.shares_trade_shipping_fee&&this.tableData.trade_pay_amount==0)) {
          this.step = 9;
          console.log(this.settingData,"this.settingData.shares_trade_shipping_fee")
        }
        if (
          this.tableData.platform_state > 0 &&
          this.tableData.trade_shipments.length >= 0
        ) {
          this.step = 10;
        }
        if (this.tableData.trade_state == 0) {
          this.messageList.forEach((e, index) => {
            if (index > this.step - 1) {
              console.log(1);
              e.itemShow = false;
            } else {
              e.itemShow = true;
            }
          });
          this.messageList[10].itemShow = true;
          console.log(this.step, "2", this.messageList);
        } else {
          if (this.step > 4) {
            this.messageList.forEach((item, index) => {
              if (index > 3) {
                item.itemShow = true;
              } else {
                item.itemShow = false;
              }
            });
          }
          this.messageList[10].itemShow = false;
        }
        // 判斷展示与隐藏
        // if(this.step>3){
        //   this.messageList.forEach((e,index)=>{
        //   if(index>this.step){
        //     e.itemShow = true
        //   }else{
        //     e.itemShow = false
        //   }
        // })
        // }
        console.log(this.step, "111");
        this.$forceUpdate();
        if (this.tableData.trade_shipments.length > 0) {
          this.logistics();
        }
      }
    },
    payBtn(index) {
      if (index == 1) {
        this.$router.push(`./pay?id=${this.tableData.trade_id}`);
      } else if (index == 2) {
        this.$router.push(`./pay?id=${this.tableData.trade_id}&type=1`);
      } else {
        this.$router.push(
          `./freight?id=${this.tableData.trade_id}&status=1&freight=${
            this.settingData.shares_trade_shipping_fee
          }`
        );
      }
    },
    goReturn(){
      this.$router.push("../deal/return")
    }
  }
};
</script>
<style>
/*tansition的name属性与样式的前缀需要对应，此处是hello*/
.hello-enter-active {
  animation: slide 0.5s linear;
}

.hello-leave-active {
  animation: slide 0.5s linear reverse;
}

@keyframes slide {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0px);
  }
}
.van-toast {
  width: 300px;
  min-height: 120px;
}
.van-toast__text {
  font-size: 35px;
  line-height: 50px;
}
.van-icon {
  font-size: 40px;
}
.van-dialog {
  width: 500px;
  font-size: 35px !important;
}
.van-dialog__message {
  font-size: 25px;
  line-height: 40px;
}
.cloneBtn {
  background: #deb77a;
  width: 160px;
  height: 51px;
  color: #fff;
  font-size: 25px;
  border-radius: 20px;
  text-align: center;
  line-height: 51px;
  margin-left: 500px;
  margin-bottom: 50px;
}
.footerText{
  display: flex;
  font-size:25px;
  margin-top: 120px
}
.footerText div{
  width:540px
}
.footerText span{
  color:#FF3E46
}
.receiver div {
  margin-bottom: 20px;
  color: #707070ff;
}
.time_text {
  font-size: 20px;
  color: #000;
  margin: 10px 0 20px 10px;
}
.pop3 {
  height: 776px;
  background: #ffffff;
  font-size: 16px;
  color: #707070;
  padding: 20px 20px 20px 50px;
}
.payClass {
  color: #4b93ff;
  font-size: 25px;
  margin-left: 10px;
}
.shipper_box {
  width: 700px;
  height: 800px;
  background-color: #fff;
  margin: 200px auto 0;
  font-size: 20px;
  color: #00000080;
  overflow-y: scroll;
  position: relative;
  padding: 50px;
  box-sizing: border-box;
}
.flow_text {
  font-size: 25px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #deb77a;
  text-align: right;
  margin: 25px 0;
}
.flow_contanter {
  padding: 0 38px;
  box-sizing: border-box;
}
.userBtn {
  float: right;
  text-align: center;
}
.userBtn img {
  width: 36px;
  height: 36px;
}
.userBtn div {
  font-size: 15px;
  color: #000;
}
.flow_box {
  width: 37px;
  height: 37px;
  font-size: 22px;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 37px;
  color: #737373;
  opacity: 1;
  text-align: center;
  border: 2px solid #737373;
  border-radius: 50%;
}
.flow_content {
  margin-top: 20px;
}
.line_flow {
  width: 1px;
  border: 1px dashed #737373;
  margin: 0 18px;
  height: 150px;
}
.flow_list {
  display: flex;
  padding: 30px auto;
}
.flow_imgBtn {
  width: 24px;
  height: 24px;
  vertical-align: middle;
  margin: auto 0;
}
.flow_title {
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: bold;
  color: rgba(115, 115, 115, 0.5);
  margin: 0 10px;
}
.flow_text1 {
  margin-left: 10px;
  font-size: 25px;
  color: #4b93ff;
  margin-top: 15px;
  line-height: 35px;
}
.flow_popbox {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  padding-top: 30px;
}
.flow_pop1 {
  width: 600px;
  /* height:700px; */
  background-color: #fff;
  margin: 200px auto 0;
  /* text-align:right; */
  padding: 100px 20px 20px;
  box-sizing: border-box;
  position: relative;
}
.flow_pop1 img {
  position: absolute;
  top: 20px;
  right: 20px;
}
.pop1 {
  font-size: 22px;
}
.pop1 > div {
  margin: 20px 60px;
  text-align: none;
}
.title_pop {
  width: 130px;
  display: inline-block;
  color: #707070ff;
}
.pop3 > div {
  margin-top: 20px;
}
.formList_flow {
  display: flex;
}
.offine_title_flow {
  width: 100px;
  font-size: 22px;
  color: #707070;
  margin: 0;
}
.pop1 .submitBtn {
  width: 160px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  font-size: 25px;
  border: 1px solid #ff5e5e;
  color: #ff5e5e;
  margin: 20px auto 0;
}
.address_text_flow {
  width: 300px;
}
.pop2 > div {
  font-size: 26px;
  color: #707070;
  margin: 20px 0;
}
.pop2 {
  margin-left: 80px;
}
.selectColor {
  color: #ff5e5e;
  border: 1px solid #ff5e5e;
}
.selectLine {
  border: 1px dashed #ff5e5e;
}
@step-icon-size {
  font-size: 40px;
}
.title_text {
  color: #4b93ff;
  margin: 0px 0 0 20px !important;
  font-size: 24px;
}
.card {
  margin: 20px 0;
}
.success2 {
  width: 24px;
  height: 24px;
  margin: 10px 0 0 5px;
}
.messageIcon {
  width: 36px;
  height: 36px;
  margin-right: 20px;
}
.messageTitle1 {
  text-align: right;
  margin-right: 20px;
}
.loginBtn1 {
  margin: 20px 0;
  display: flex;
  /* margin-left: 71px */
}
.loginBtn1 > div {
  /* display: flex; */
  /* margin-left: 77px; */
}
.loginBtn1 span {
  margin: 0 5px;
}
.comfigBtn {
  width: 124px;
  height: 40px;
  background-color: #4b93ff;
  border-radius: 5px;
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 0px;
  color: #ffffff;
  text-align: center;
  line-height: 40px;
  margin-left: 79px;
}
.getAddress {
  display: flex;
  margin-left: 70px;
  margin-top: 30px;
}
.steps1 {
  /* height: 200px; */
  display: flex;
  margin-left: 60px;
}
.steps1 .colorText {
  color: #000000;
}
.steps1 .neth div {
  margin: 5px 0;
}
.neth {
  padding: 5px 0 20px 10px;
}
.linecolor {
  width: 1px;
  height: 100%;
  opacity: 1;
  border: 1px dashed #ff5e5e;
  margin-left: 18.5px;
}
.linecolor1 {
  width: 1px;
  height: 100%;
  opacity: 1;
  border: 1px solid #d1d1d1;
  margin-left: 6.5px;
}
.linecolor2 {
  width: 1px;
  height: 100%;
  opacity: 1;
  border: 1px solid #d1d1d1;
  margin-left: 6.5px;
}
.boxcolor {
  width: 37px;
  height: 37px;
  background: #ffffff;
  border: 2px solid #ff5e5e;
  border-radius: 50%;
  opacity: 1;
  text-align: center;
  line-height: 37px;
  color: #ff5e5e;
  font-size: 22px;
}
.boxcolor1 {
  width: 13px;
  height: 13px;
  background: #ff5e5e;
  border-radius: 50%;
  opacity: 1;
}
.boxcolor2 {
  width: 13px;
  height: 13px;
  background: #d1d1d1;
  border-radius: 50%;
  opacity: 1;
}
.boxcolor3 {
  width: 44px;
  height: 44px;
  background: #fc6305;
  border-radius: 50%;
  opacity: 1;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  line-height: 44px;
}
.cart {
  width: 120px;
  height: 120px;
  margin: 190px auto 0;
}
.cart img {
  width: 100%;
  height: 100%;
}
.auditInfo {
  margin: 50px 0 0 37px;
}
.content1 {
  font-size: 22px;
  color: rgba(0, 0, 0, 0.5);
  padding-top: 40px;
  box-sizing: border-box;
  width: 100%;
}
.steps {
  height: 150px;
  padding-left: 61px;
}
.step_text {
  font-size: 22px;
  margin: 52px 0 0 54px;
}
.van-step--vertical {
  font-size: 22px;
  color: #000000;
}
.van-step--vertical {
  /* height: 200px; */
  line-height: 45px;
}
.title1 {
  color: #ff5e5e;
}
.van-step__icon--active,
.van-step__icon--finish,
.van-step__title--active,
.van-step__title--finish {
  color: #ff5e5e;
}
.submit {
  width: 160px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background: #4b93ff;
  opacity: 1;
  border-radius: 5px;
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
  opacity: 1;
  margin-left: 100px;
  margin-top: 20px;
}
.logistics {
  margin-left: 70px;
}
.cloneBtn2 {
  position: absolute;
  top: 20px;
  right: 20px;
}
.order {
  width: 115px;
  height: 52px;
  background: #ffffff;
  border: 2px solid #ff5e5e;
  opacity: 1;
  border-radius: 5px;
  text-align: center;
  line-height: 51px;
  font-size: 25px;
  color: #ff5e5e;
  margin-left: 70px;
  position: absolute;
  right: 20px;
  top: 80px;
}
.lookMore {
  width: 619px;
  height: 50px;
  background: #ffffff;
  border: 2px solid #0f87ff;
  opacity: 1;
  border-radius: 5px;
  font-size: 30px;
  color: #0f87ff;
  line-height: 50px;
  text-align: center;
  margin: 0 auto 30px;
}
.check {
  width: 126px;
  height: 40px;
  background: #07c160;
  opacity: 1;
  border-radius: 20px;
  font-size: 20px;
  text-align: center;
  line-height: 40px;
  color: #fff;
  margin: -3px 0 0 20px;
}
.check img {
  width: 13px;
  height: 13px;
  margin-left: 5px;
}
.flow_header .bg_line {
  color: #ababab;
  border: 2px solid #ababab;
}
.flow_header .bs_line {
  color: #ff5e5e;
  border: 2px solid #ff5e5e;
}
.bg {
  color: #ababab;
}
.bc {
  color: #ff5e5e;
}
.payBtn {
  width: 126px;
  height: 40px;
  background: #07c160;
  opacity: 1;
  border-radius: 20px;
}
</style>